.history {
  position: fixed;
  z-index: 50;
  top: 0; 
  left: 0;
  height: 100%;
  width: 100%;
  padding: 100px;
  /*background: linear-gradient(135deg, rgba(20,207,204,.8), rgba(56,101,173,.8), rgba(130,69,150,.8));*/
  background: linear-gradient(135deg, hsl(179 100% 45% / .8), hsl(217 100% 45% / .8), hsl(285 100% 43% / .8));
  transition: opacity .35s;
}
.history:not(.show) {
  opacity: 0;
  pointer-events: none;
}


@media only screen and (max-width: 1200px) {
  .history {
    padding: 40px;
  }
}

.mobile .history {
  background: transparent;
  transition: none;
}
.mobile .history:not(.show) {
  opacity: 1;
}

.history-window {
  position: relative;
  color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  background: rgba(0,0,30,.4);
  border: 4px solid rgba(0,0,30,.3);
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
  display: flex;
  flex-direction: column;
  transition: transform .25s cubic-bezier(0.000, 1.040, 0.240, 1.240);
}
.history:not(.show) .history-window {
  box-shadow: none;
  transform: scale(0);
}

.mobile .history-window {
  position: absolute;
  left: 0;
  height: calc(100% - 40px);
  background: linear-gradient(135deg, #126b89, #053289, #471486);
  border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: 40px;
  transform: translateZ(0);
  transition: top .2s ease;
}
.mobile .history:not(.show) .history-window {
  top: 100%;
  transform: translateZ(0);
  transition: top .2s ease;
  overflow: visible;
}

.history svg {
  fill: white;
}
.history .search {
  z-index: 100;
  flex-shrink: 0;
  flex-grow: 0;
  background: rgba(0,0,30,.3);
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
}
.history .search svg {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 30px;
  width: 30px;
}
.history .search input {
  font-family: 'Rubik', sans-serif;
  background: transparent;
  color: white;
  border: none;
  outline: none;
  width: 100%;
  font-size: 30px;
  padding: 20px 30px;
  padding-left: 70px;
}
.history .search input::placeholder {
  color: hsla(220, 39%, 80%, .8);
}
.mobile .history .search {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  font-size: 20px;
  color: hsla(220, 39%, 80%, .8);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  position: relative;
}
.mobile .history .search .btn {
  position: absolute;
  width: 75px;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  border-top-right-radius: 15px;
}
.mobile .history .search svg {
  position: initial;
  height: 15px;
  width: auto;
}
.mobile .history .search .btn span {
  position: initial;
  display: block;
  transform: rotate(45deg);
  font-size: 25px;
  font-weight: 300;
  line-height: 1em;
  color: white;
}

.history .content {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  height: 100%;
  min-height: 0;
}

.history .transactions {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 20px;
}
.history .filters {
  margin: 0 -20px 20px -20px;
  padding: 10px 20px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
}
.history .pill {
  font-size: 12px;
  border-radius: 7px;
  padding: 4px 8px;
  margin-right: 10px;
  text-transform: uppercase;
  color: rgb(0,0,30);
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;
}
.history:not(.mobile) .pill:not(.active):not(:hover) {
  opacity: .7;
  box-shadow: none!important;
}
.history.mobile .pill:not(.active) {
  opacity: .7;
  box-shadow: none!important;
}
.history .pill.active {
  background: rgba(0,0,30,.3);
}
.history .row {
  margin-bottom: 10px;
  background: rgba(0,0,30,.3);
  border-radius: 10px;
  padding-left: 10px;
  position: relative;
  line-height: 1em;
  cursor: default;
}
.history .row.clickable {
  cursor: pointer;
}
.history .row .row-content {
  padding: 10px 30px;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
}
.history .row .banner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.history .row .meta { 
  flex-shrink: 0;
  white-space: nowrap;
  padding-right: 30px;
}
.history .row .meta.text {
  text-transform: capitalize;
  font-size: 12px;
}
.history .row .meta:not(.text) {
  width: 100px;
}
.history .row .meta:last-child {
  padding-right: 30px;
  width: auto;
}
.history .row small {
  display: block;
  font-size: 11px;
  color: hsla(220, 39%, 80%, .8);
  margin-top: -1px;
}
.history .row p {
  margin: 0;
}
.history .mute {
  color: hsla(220, 39%, 80%, .8);
}
.history .footer {
  margin: 50px 0;
  text-align: center;
  color: hsla(220, 39%, 80%, .8);
  user-select: none;
}

.history .details {
  width: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  border-left: 3px solid rgba(0,0,30,.2);
  position: relative;
  display: flex;
  flex-direction: column;
}
.mobile .history .details {
  border: none;
  position: absolute;
  top: 0;
  left: 100%;
  background: linear-gradient(135deg, #126b89, #053289, #471486);
  z-index: 100;
  width: 100%;
  transition: left .35s, box-shadow .2s;
}
.mobile .history .details.show {
  left: 0;
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
}
.history .details-content {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  padding: 20px;
  padding-bottom: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}
.history .details .divider {
  width: 100%;
  height: 2px;
  background: white;
  opacity: .1;
  margin: 20px 0;
}
.history .details .sym {
  font-size: 50px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 15px;
  position: relative;
  user-select: none;
}

.history .details .sym .balance {
  position: absolute;
  top: -5px;
  right: 0;
  display: block;
  text-align: right;
  padding-right: 8px;
}
.history .details .sym .percent-tag {
  position: absolute;
  right: -5px;
  display: flex;
  transform: scale(.8);
}
.history .details .sym > span {
  color: hsla(220, 39%, 80%, .8);
}
.history .details .sym .active-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: white;
  display: inline-block;
  margin-left: 10px;
  transform: translateY(-.55em);

  animation-name: pulse-dot;
  animation-duration: 1s;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: forward;
}
@keyframes pulse-dot {
  0% { opacity: .5; }
  50% { opacity: 1; }
  100% { opacity: .5; }
}
.history .details .balance {
  position: relative;
  font-size: 35px;
  line-height: 1em;
  display: flex;
  align-items: flex-start;
  font-weight: 200;
  user-select: none;
}
.history .details .balance small {
  font-size: .5em;
  line-height: 1em;
  margin-top: 3px;
  align-self: flex-start;
  letter-spacing: 1px;
  margin-left: 2px;
  font-weight: 400;
}
.history .details .percent {
  margin-left: 15px;
  font-size: 15px;
  height: 25px;
  align-self: flex-end;
}
.history .details .metrics {
  margin-bottom: -20px;
}
.history .details .metrics.active {
  margin-bottom: 0;
  margin-top: 20px;
  background: rgba(0,0,30,.1);
  border: 2px solid white;
  border: none;
  border-radius: 10px;
  padding: 20px;
  padding-top: 17px;
  padding-bottom: 0;
}
.history .details .metric {
  display: inline-block;
  padding-right: 10px;
  width: 30%;
  margin-bottom: 20px;
}
.history .details .metrics.active .metric {
  width: 25%;
}
.history .details .metrics.active .metric.double {
  width: 50%;
  padding-right: 0;
}
.history .details .metrics:not(.active) .metric:nth-of-type(3n) {
  padding-right: 0;
  width: 40%;
}
.history .details .metrics.active .mute {
  display: inline-block;
  margin: 0 2px;
  opacity: .5;
}
.history .details .metric .header {
  font-size: 12px;
  color: hsla(220, 39%, 80%, .8);
  user-select: none;
}
.history .details .year .toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
  margin: -20px -10px;
  padding: 20px 10px;
  border-radius: 10px;
  min-height: 65px;
  font-size: 20px;
  user-select: none;
}
.history .details .year h1 {
  font-size: 25px;
  margin: 0;
  font-weight: 400;
}
.history .details .year .toggle > div > :first-child {
  display: inline-block;
  font-weight: 200
}
.history:not(.mobile) .details .year .toggle:not(.disabled):hover {
  cursor: pointer;
  background: rgba(0,0,30,.1);
}
.history .details .year .toggle.disabled {
  color: hsla(220, 39%, 80%, .8);
}
.history .details .year .extended {
  margin-top: 40px;
  margin-bottom: 20px;
}
.history .details .year .extended .trades {
  margin-top: 40px;
}
.history .details .year .extended .trade {
  background: rgba(0,0,30,.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  padding-right: 8px;
  margin: 5px -5px;
  border-radius: 10px;
}
.history .details .year .extended .trade > :first-child {
  font-size: 13px;
  color: hsla(220, 39%, 80%, .8);
}
.history .details .year .extended .trade > :last-child > :first-child {
  display: inline-block;
  transform: translateY(-1px);
}
.history .details .year .extended .trade .percent-tag {
  border-radius: 6px;
  font-size: 12px;
  padding: 0px 5px;
  height: 20px;
}
.history .details .year .extended .trade .percent-tag svg {
  width: 10px;
  margin-right: 5px;
}
.history.mobile .details .year .toggle .percent-tag {
  transform: scale(.8);
  margin-left: 2px;
}



.history .stock-opts li {
  display: inline-block;
  user-select: none;
  background: rgba(0,0,30,.3);
  border-radius: 100px;
  margin: 4px;
  color: white;
  opacity: 1;
  border: 2px solid transparent;
}
.history .symbols-list .stock-opts li,
.history .empty-details .stock-opts li {
  transform: scale(0);
}
.history .stock-opts li .active-dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 2px;
  background: white;
  transform: translateY(-.44em);
}
.history:not(.mobile) .stock-opts li:hover {
  border-color: hsla(220, 39%, 80%, .8);
}
.history .stock-opts li.single.active,
.history .stock-opts li.active {
  border-color: white;
  border-radius: 50px;
  background: rgba(0,0,30,.3);
}
.history .stock-opts li.active-trade {
  color: #4dffff;
  background: rgba(0,0,30,.3);
}
.history .stock-opts li.active.active-trade {
  border-color: #4dffff;
}
.history .stock-opts li.active-trade .active-dot {
  background: #4dffff;
  transform: translateY(-.5em);
}

.history .details > .stock-opts {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px 20px;
  position: relative;
  background: rgba(0,0,30,.2);
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
}
.history .details > .stock-opts li:first-child {
  margin-left: -10px;
}
.history .details > .stock-opts li:last-child {
  margin-right: -10px;
}
.history .empty-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  min-height: 100%;
}
.history .empty-details .stock-opts {
  width: 100%;
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
}
.history.mobile .detail-transactions {
  margin-top: 60px;
}
.history.mobile .details .transactions {
  padding: 0;
  margin-top: 20px;
}
.history.mobile .details .footer {
  margin-bottom: 0;
}

.history .clear {
  display: none;
  height: 60px;
  width: 60px;
  padding: 20px;
  border-radius: 30px;
  position: absolute;
  right: -15px;
  top: -5px;
  cursor: pointer;
}
.history .clear svg {
  height: 20px;
  width: 20px;
}
.history .clear:not(:hover) svg {
  fill: hsla(220, 39%, 80%, .8);
}

@media only screen and (max-width: 767px) {
  .history:not(.mobile) .content {
    flex-direction: column;
  }
  .history:not(.mobile) .transactions {
    height: 100%;
  }
  .history:not(.mobile) .details {
    width: 100%;
    border-left: none;
    height: 30%;
    flex-shrink: 0;
    flex-grow: 0;
    border-top: 3px solid rgba(0,0,30,.2);
    transition: height .15s;
  }
  .history:not(.mobile) .details.with-sym {
    height: 75%;
  }
  .history:not(.mobile) .clear {
    display: flex;
  }
}

.history .symbols-list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  flex-grow: 1;
}
.history.mobile .symbols-list {
  padding: 40px 15px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}
.history .symbols-list .stock-opts {
  display: block;
  text-align: center;
}
.history .symbols-list-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.history .symbols-list .stock-opts li {
  font-size: 20px;
}
.history .symbols-list .stock-opts li.active {
  background: rgba(0,0,30,.3);
  border-color: hsla(220, 39%, 80%, .8);
  border-radius: 100px;
}