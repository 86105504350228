#breakdown .body {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 100;
  flex-shrink: 1;
  backface-visibility: hidden;
}

#breakdown .square {
  position: absolute;
}

#breakdown .square:not([data-sized]) svg {
  opacity: 0;
  stroke-dasharray: 0!important;
}
#breakdown .main svg {
  position: absolute;
  transform: rotate(-90deg) translate3d(0,0,0);
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 2s, transform .2s;
}
#breakdown .main circle {
  fill: none;
  stroke-linecap: round;
  stroke-width: 5px;
  vector-effect: non-scaling-stroke;
  stroke-dashoffset: 0;
  stroke-dasharray: 0;
  transition: stroke-dashoffset .2s, stroke-dasharray .2s;
}
#breakdown .main circle.track {
  stroke: #445db1;
  stroke-width: 1px;
  stroke-dasharray: 5;
  transition: none;
}
#breakdown .main circle.mark {
  stroke: #445db1;
  stroke-width: 3.5px;
  z-index: 1;
  transition: none;
}

#breakdown .space {
  width: 50%;
  height: 100%;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#breakdown .stats-container {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: auto;
}
#breakdown .keys {
  display: flex;
  flex-direction: column;
  margin: auto;
}
#breakdown .key {
  user-select: none;
  position: relative;
  padding: 1vh 0;
  display: flex;
  opacity: .2;
  flex-shrink: 1;
  flex-grow: 0;
  cursor: pointer;
  transition: opacity .2s, transform .1s;
}
#breakdown .key[data-type="cash"] {
  pointer-events: none;
}
#breakdown .key.active {
  opacity: 1;
}
#breakdown .key .icon {
  margin-right: 7px;
  display: flex;
  align-items: center;
}
#breakdown .key .icon div {
  height: 7px;
  width: 7px;
  border-radius: 50%;
}
#breakdown .key .title {
  text-transform: capitalize;
  line-height: 1em;
}
#breakdown .key:hover {
  transform: scale(1.1);
}
#breakdown .key .title span {
  margin-left: 8px; 
  opacity: .5;
  font-weight: 500;
  font-size: .8em;
  text-transform: uppercase;
  display: inline-block;
  min-width: 35px;
}


#breakdown .data {
  overflow: hidden;
}
#breakdown .slides {
  height: 100%;
  width: 100%;
  display: flex;
  transition: transform .35s;
}
.mobile #breakdown .slides {
  transform: translateX(0);
}
#breakdown .slide {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

#breakdown .position {
  border-radius: 10px;
  background: rgb(0 0 0 / .3);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-left: 20px;
  font-weight: 400;
}
#breakdown .position .sym {
  text-transform: uppercase;
  font-size: 15px;
}
#breakdown .position > div {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 12px;
}
#breakdown .position .perc {
  border-radius: 8px;
  padding: 5px 8px;
  margin-left: 10px;
  min-width: 55px;
  text-align: center;
  color: black;
}

.no-animations #breakdown .main svg,
#breakdown .main circle {
  transition: none;
}