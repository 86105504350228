@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900');

/*
== COLORS ==
#18ecdd hsl(176, 85%, 51%) = green
#4a8efc hsl(217, 97%, 64%) = blue   x
#aec2fa hsl(224, 88%, 83%) = purple
#ff85e4 hsl(313, 100%, 76%) = pink
#445db1 hsl(226, 44%, 48%) = darkblue

hsla(220, 39%, 80%, .8) = practically opacity white but blue
*/

* {
  outline: none;
  box-sizing: border-box;
}
.mobile * {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
::selection {
  background: rgba(255,255,255,.1);
}
.hide-scroll::-webkit-scrollbar,
.mobile *::-webkit-scrollbar {
  display: none;
}

html {
  background: #069cc2;
  /*background: linear-gradient(135deg, hsl(179 100% 45% / 1), hsl(217 100% 45% / 1), hsl(285 100% 43% / 1));*/
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  color: white;
  width: 100%;
  height: 100vh;
  font-size: 15px;
  z-index: 10;
  position: fixed;
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
  background: #069cc2;
  /*background: linear-gradient(135deg, rgba(20,207,204,1), rgba(56,101,173,1), rgba(130,69,150,1));*/
  background: linear-gradient(135deg, hsl(179 100% 45% / 1), hsl(217 100% 45% / 1), hsl(285 100% 43% / 1));
}

.app {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: rgba(0,0,0,.15);*/
}

.app > .rotate {
  display: none;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.app > .rotate svg { height: 20vh; width: 20vh; }
.app > .rotate .primary { fill: rgba(255,255,255,0.5); }
.app > .rotate .secondary { fill: rgba(255,255,255,0.1); }

 @media screen and (orientation:landscape) {
/*  .mobile.app {
    height: 100vw;
    width: 100vh;
    transform: translateX(-100%) rotate(-90deg); 
    transform-origin: right top;
  }*/
  .mobile.app:not(.dev) > :not(.rotate) { display: none; }
  .mobile.app:not(.dev) > .rotate { display: flex; }
}

.unlock {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.6);
  opacity: 0;
  overflow: hidden;
}
.unlock .input {
  text-align: center;
  -webkit-text-security: disc;
  cursor: text;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 1em;
  color: white;
  padding-left: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.unlock .input:empty:before {
  content: 'pass';
  opacity: .2;
  margin-left: 1em;
}
.unlock .input:after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}
.unlock .logo {
  width: 40%;
  margin-bottom: 50px;
}
 .unlock-error {
  position: absolute;
  bottom: 15%;
  cursor: pointer;
  transition: opacity 150ms;
  background: rgba(255,255,255,.1);
  padding: .75em 1.5em;
  font-size: 1em;
  border-radius: .5em;
  z-index: 20;
}
.unlock-error:hover { opacity: .8 }
.unlock-error.hide { opacity: 0 }
@media only screen and (max-width: 767px) {
  .unlock .input {
    font-size: 1.2em;
    letter-spacing: .5em;
    padding-left: .5em;
  }
  .unlock .input:empty:before {
    margin-left: .5em;
  }
  .unlock .logo {
    margin-bottom: 25px;
    width: 60%;
  }
}

/* SWIPE LIBRARY */
.swipe {
  visibility: hidden;
  position: relative;
}
.swipe-wrap {
  position: relative;
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
}