#balances .dsign {
  margin-right: .2em;
}
#balances .psign {
  margin: 0 .3em 0 .15em;
}

#balances .body,
#balances .main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  font-weight: 100;
}

#balances .main {
  user-select: none;
}

#balances .balance,
#balances .val {
  position: relative;
  font-size: 5vh;
  line-height: 1em;
  display: flex;
  align-items: flex-start;
  margin-top: -.2em;
}

#balances small {
  font-size: .5em;
  line-height: 1.5em;
  letter-spacing: 1px;
  margin-left: 2px;
  font-weight: 400;
}

#balances .stats {
  display: flex;
  justify-content: space-between;
}

#balances .stat {
  flex-grow: 1;
  width: 100%;
  margin-right: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#balances .stats > :last-child {
  margin-right: 0;
}

#balances .stat .val {
  font-size: 3.2vh;
  margin-bottom: 2px;
  align-items: flex-end;
}
#balances .stat .title {
  font-size: 1.8vh;
  color: hsla(220, 39%, 80%, .8);
}
#balances .stat .range {
  margin: 7px 0;
  height: 6px;
  width: 100%;
  background: hsla(220, 39%, 65%, .2);
  border-radius: 90000px;
}
#balances .stat .range div {
  border-radius: 90000px;
  height: 100%;
  width: 0%;
  transition: width .2s;
}
#balances .stat .details {
  display: flex;
  justify-content: space-between;
  font-size: 1.2vh;
  color: hsla(220, 39%, 80%, .8);
  text-transform: uppercase;
}
#balances .stat:hover .details,
#balances .stat:hover .title {
  color: white;
}

#balances .data > table {
  max-width: 450px;
  width: 100%;
}
#balances .data td {
  padding: 0;
}
#balances .data tr:nth-of-type(even):not(:last-child) td {
  padding-bottom: 20px;
}
#balances .data tr td:nth-of-type(2) {
  padding-right: 30px;
  padding-left: 30px;
}
#balances .data-row .thin {
  width: 30%;
}
#balances .label-row td {
  user-select: none;
  line-height: 1em;
  opacity: .5;
  font-weight: 500;
  font-size: .8em;
  text-transform: uppercase;
  vertical-align: bottom;
}
#balances .data-row td {
  vertical-align: top;
}
#balances .value-row:not(:last-child) {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  #balances .balance { font-size: 3em }
  #balances .stat .title { font-size: .9em }
  #balances .stat .details { font-size: .65em }
}

.mobile #balances .balance { font-size: 2em }
.mobile #balances .data tr:nth-of-type(even):not(:last-child) td { padding-bottom: 10px; }
.mobile #balances .label-row td { font-size: .6em; }
.mobile #balances .data-row td { 
  font-size: .8em; 
  line-height: 1em;
}
.mobile #balances .data tr td:nth-of-type(2) {
  padding-left: 5px!important;
  padding-right: 5px!important;
}

.no-animations #balances .stat .range div {
  transition: none;
}