@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900);
#header-cal:hover .dates {
  opacity: 1;
}

.scrubber-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrubber {
  width: 70%;
  display: flex;
  position: absolute;
  padding: 0 2em;
  margin-top: 8px;
}
.scrubber canvas {
  height: 4.5em;
  width: 100%;
  padding: 1.5em 0;
}
.scrubber .arrow svg {
  width: 1em;
  height: 1em;
  fill: white;
}
.scrubber .arrow { 
  margin-top: 1px;
  position: absolute; 
  opacity: .5;
  height: 100%;
  display: flex;
  align-items: center;
}
.scrubber .backwards { left: 0 }
.scrubber .backwards svg { transform: rotate(-90deg) }
.scrubber .forwards { right: 0 }
.scrubber .forwards svg { transform: rotate(90deg) }
.scrubber .arrow:hover { opacity: 1 }
.scrubber .arrow span { margin-top: .2em }
.scrubber .from, .scrubber .to {
  opacity: .3;
  position: absolute;
  top: 5px;
}
.scrubber .to { right: 2em }
.scrubber .dates {
  opacity: .3;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  position: absolute;
  font-size: 1em;
}

.scrubber .dates.dragging > :not(.drag) {
  display: none;
}
#portfolio .body {
  display: flex;
  flex-shrink: 1;
  cursor: default;
}
#portfolio .chart-wrapper {
  display: flex;
  position: relative;
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  margin: -10px -10px;
}

.portfolio-tooltip {
  position: absolute;
  pointer-events: none;
  background: rgba(255,255,255,.1);
  border-radius: 5px;
  padding: 5px 8px;
}
.portfolio-tooltip .title {
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .portfolio-tooltip {
    margin: 0;
  }
}
#balances .dsign {
  margin-right: .2em;
}
#balances .psign {
  margin: 0 .3em 0 .15em;
}

#balances .body,
#balances .main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  font-weight: 100;
}

#balances .main {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#balances .balance,
#balances .val {
  position: relative;
  font-size: 5vh;
  line-height: 1em;
  display: flex;
  align-items: flex-start;
  margin-top: -.2em;
}

#balances small {
  font-size: .5em;
  line-height: 1.5em;
  letter-spacing: 1px;
  margin-left: 2px;
  font-weight: 400;
}

#balances .stats {
  display: flex;
  justify-content: space-between;
}

#balances .stat {
  flex-grow: 1;
  width: 100%;
  margin-right: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#balances .stats > :last-child {
  margin-right: 0;
}

#balances .stat .val {
  font-size: 3.2vh;
  margin-bottom: 2px;
  align-items: flex-end;
}
#balances .stat .title {
  font-size: 1.8vh;
  color: hsla(220, 39%, 80%, .8);
}
#balances .stat .range {
  margin: 7px 0;
  height: 6px;
  width: 100%;
  background: hsla(220, 39%, 65%, .2);
  border-radius: 90000px;
}
#balances .stat .range div {
  border-radius: 90000px;
  height: 100%;
  width: 0%;
  transition: width .2s;
}
#balances .stat .details {
  display: flex;
  justify-content: space-between;
  font-size: 1.2vh;
  color: hsla(220, 39%, 80%, .8);
  text-transform: uppercase;
}
#balances .stat:hover .details,
#balances .stat:hover .title {
  color: white;
}

#balances .data > table {
  max-width: 450px;
  width: 100%;
}
#balances .data td {
  padding: 0;
}
#balances .data tr:nth-of-type(even):not(:last-child) td {
  padding-bottom: 20px;
}
#balances .data tr td:nth-of-type(2) {
  padding-right: 30px;
  padding-left: 30px;
}
#balances .data-row .thin {
  width: 30%;
}
#balances .label-row td {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 1em;
  opacity: .5;
  font-weight: 500;
  font-size: .8em;
  text-transform: uppercase;
  vertical-align: bottom;
}
#balances .data-row td {
  vertical-align: top;
}
#balances .value-row:not(:last-child) {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  #balances .balance { font-size: 3em }
  #balances .stat .title { font-size: .9em }
  #balances .stat .details { font-size: .65em }
}

.mobile #balances .balance { font-size: 2em }
.mobile #balances .data tr:nth-of-type(even):not(:last-child) td { padding-bottom: 10px; }
.mobile #balances .label-row td { font-size: .6em; }
.mobile #balances .data-row td { 
  font-size: .8em; 
  line-height: 1em;
}
.mobile #balances .data tr td:nth-of-type(2) {
  padding-left: 5px!important;
  padding-right: 5px!important;
}

.no-animations #balances .stat .range div {
  transition: none;
}
#volatility .bars {
  height: 130px;
  position: relative;
  display: flex;
  justify-content: space-between;
  direction: rtl;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 10px;
  padding-left: 0;
  align-items: flex-end;
}
#volatility .bar {
  height: 100%;
  min-height: 6px;
  width: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  transition: height .2s;
}
#volatility .bar:before {
  content: '';
  display: block;
  height: 100%;
  width: 6px;
  position: absolute;
  top: 0;
  left: 4px;
  background: #b399ff;
  border-radius: 3px;
  box-shadow: rgba(179, 153, 255, 0.7) 0px 0px 7px 0px;
}
#volatility .bar:first-child {
  width: 10px;
}
#volatility .bar:last-child {
  width: 21px;
}
#volatility .bar:last-child:before {
  left: auto;
  right: 4px;
}

.no-animations #volatility .bar {
  transition: none;
}
#breakdown .body {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 100;
  flex-shrink: 1;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

#breakdown .square {
  position: absolute;
}

#breakdown .square:not([data-sized]) svg {
  opacity: 0;
  stroke-dasharray: 0!important;
}
#breakdown .main svg {
  position: absolute;
  transform: rotate(-90deg) translate3d(0,0,0);
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 2s, transform .2s;
}
#breakdown .main circle {
  fill: none;
  stroke-linecap: round;
  stroke-width: 5px;
  vector-effect: non-scaling-stroke;
  stroke-dashoffset: 0;
  stroke-dasharray: 0;
  transition: stroke-dashoffset .2s, stroke-dasharray .2s;
}
#breakdown .main circle.track {
  stroke: #445db1;
  stroke-width: 1px;
  stroke-dasharray: 5;
  transition: none;
}
#breakdown .main circle.mark {
  stroke: #445db1;
  stroke-width: 3.5px;
  z-index: 1;
  transition: none;
}

#breakdown .space {
  width: 50%;
  height: 100%;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#breakdown .stats-container {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: auto;
}
#breakdown .keys {
  display: flex;
  flex-direction: column;
  margin: auto;
}
#breakdown .key {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  padding: 1vh 0;
  display: flex;
  opacity: .2;
  flex-shrink: 1;
  flex-grow: 0;
  cursor: pointer;
  transition: opacity .2s, transform .1s;
}
#breakdown .key[data-type="cash"] {
  pointer-events: none;
}
#breakdown .key.active {
  opacity: 1;
}
#breakdown .key .icon {
  margin-right: 7px;
  display: flex;
  align-items: center;
}
#breakdown .key .icon div {
  height: 7px;
  width: 7px;
  border-radius: 50%;
}
#breakdown .key .title {
  text-transform: capitalize;
  line-height: 1em;
}
#breakdown .key:hover {
  transform: scale(1.1);
}
#breakdown .key .title span {
  margin-left: 8px; 
  opacity: .5;
  font-weight: 500;
  font-size: .8em;
  text-transform: uppercase;
  display: inline-block;
  min-width: 35px;
}


#breakdown .data {
  overflow: hidden;
}
#breakdown .slides {
  height: 100%;
  width: 100%;
  display: flex;
  transition: transform .35s;
}
.mobile #breakdown .slides {
  transform: translateX(0);
}
#breakdown .slide {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

#breakdown .position {
  border-radius: 10px;
  background: rgb(0 0 0 / .3);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-left: 20px;
  font-weight: 400;
}
#breakdown .position .sym {
  text-transform: uppercase;
  font-size: 15px;
}
#breakdown .position > div {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 12px;
}
#breakdown .position .perc {
  border-radius: 8px;
  padding: 5px 8px;
  margin-left: 10px;
  min-width: 55px;
  text-align: center;
  color: black;
}

.no-animations #breakdown .main svg,
#breakdown .main circle {
  transition: none;
}
.history {
  position: fixed;
  z-index: 50;
  top: 0; 
  left: 0;
  height: 100%;
  width: 100%;
  padding: 100px;
  /*background: linear-gradient(135deg, rgba(20,207,204,.8), rgba(56,101,173,.8), rgba(130,69,150,.8));*/
  background: linear-gradient(135deg, hsl(179 100% 45% / .8), hsl(217 100% 45% / .8), hsl(285 100% 43% / .8));
  transition: opacity .35s;
}
.history:not(.show) {
  opacity: 0;
  pointer-events: none;
}


@media only screen and (max-width: 1200px) {
  .history {
    padding: 40px;
  }
}

.mobile .history {
  background: transparent;
  transition: none;
}
.mobile .history:not(.show) {
  opacity: 1;
}

.history-window {
  position: relative;
  color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  background: rgba(0,0,30,.4);
  border: 4px solid rgba(0,0,30,.3);
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
  display: flex;
  flex-direction: column;
  transition: transform .25s cubic-bezier(0.000, 1.040, 0.240, 1.240);
}
.history:not(.show) .history-window {
  box-shadow: none;
  transform: scale(0);
}

.mobile .history-window {
  position: absolute;
  left: 0;
  height: calc(100% - 40px);
  background: linear-gradient(135deg, #126b89, #053289, #471486);
  border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: 40px;
  transform: translateZ(0);
  transition: top .2s ease;
}
.mobile .history:not(.show) .history-window {
  top: 100%;
  transform: translateZ(0);
  transition: top .2s ease;
  overflow: visible;
}

.history svg {
  fill: white;
}
.history .search {
  z-index: 100;
  flex-shrink: 0;
  flex-grow: 0;
  background: rgba(0,0,30,.3);
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
}
.history .search svg {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 30px;
  width: 30px;
}
.history .search input {
  font-family: 'Rubik', sans-serif;
  background: transparent;
  color: white;
  border: none;
  outline: none;
  width: 100%;
  font-size: 30px;
  padding: 20px 30px;
  padding-left: 70px;
}
.history .search input::-webkit-input-placeholder {
  color: hsla(220, 39%, 80%, .8);
}
.history .search input:-ms-input-placeholder {
  color: hsla(220, 39%, 80%, .8);
}
.history .search input::-ms-input-placeholder {
  color: hsla(220, 39%, 80%, .8);
}
.history .search input::placeholder {
  color: hsla(220, 39%, 80%, .8);
}
.mobile .history .search {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  font-size: 20px;
  color: hsla(220, 39%, 80%, .8);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  position: relative;
}
.mobile .history .search .btn {
  position: absolute;
  width: 75px;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  border-top-right-radius: 15px;
}
.mobile .history .search svg {
  position: static;
  position: initial;
  height: 15px;
  width: auto;
}
.mobile .history .search .btn span {
  position: static;
  position: initial;
  display: block;
  transform: rotate(45deg);
  font-size: 25px;
  font-weight: 300;
  line-height: 1em;
  color: white;
}

.history .content {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  height: 100%;
  min-height: 0;
}

.history .transactions {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 20px;
}
.history .filters {
  margin: 0 -20px 20px -20px;
  padding: 10px 20px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
}
.history .pill {
  font-size: 12px;
  border-radius: 7px;
  padding: 4px 8px;
  margin-right: 10px;
  text-transform: uppercase;
  color: rgb(0,0,30);
  display: inline-flex;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 2px solid transparent;
}
.history:not(.mobile) .pill:not(.active):not(:hover) {
  opacity: .7;
  box-shadow: none!important;
}
.history.mobile .pill:not(.active) {
  opacity: .7;
  box-shadow: none!important;
}
.history .pill.active {
  background: rgba(0,0,30,.3);
}
.history .row {
  margin-bottom: 10px;
  background: rgba(0,0,30,.3);
  border-radius: 10px;
  padding-left: 10px;
  position: relative;
  line-height: 1em;
  cursor: default;
}
.history .row.clickable {
  cursor: pointer;
}
.history .row .row-content {
  padding: 10px 30px;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
}
.history .row .banner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.history .row .meta { 
  flex-shrink: 0;
  white-space: nowrap;
  padding-right: 30px;
}
.history .row .meta.text {
  text-transform: capitalize;
  font-size: 12px;
}
.history .row .meta:not(.text) {
  width: 100px;
}
.history .row .meta:last-child {
  padding-right: 30px;
  width: auto;
}
.history .row small {
  display: block;
  font-size: 11px;
  color: hsla(220, 39%, 80%, .8);
  margin-top: -1px;
}
.history .row p {
  margin: 0;
}
.history .mute {
  color: hsla(220, 39%, 80%, .8);
}
.history .footer {
  margin: 50px 0;
  text-align: center;
  color: hsla(220, 39%, 80%, .8);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.history .details {
  width: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  border-left: 3px solid rgba(0,0,30,.2);
  position: relative;
  display: flex;
  flex-direction: column;
}
.mobile .history .details {
  border: none;
  position: absolute;
  top: 0;
  left: 100%;
  background: linear-gradient(135deg, #126b89, #053289, #471486);
  z-index: 100;
  width: 100%;
  transition: left .35s, box-shadow .2s;
}
.mobile .history .details.show {
  left: 0;
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
}
.history .details-content {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  padding: 20px;
  padding-bottom: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}
.history .details .divider {
  width: 100%;
  height: 2px;
  background: white;
  opacity: .1;
  margin: 20px 0;
}
.history .details .sym {
  font-size: 50px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 15px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.history .details .sym .balance {
  position: absolute;
  top: -5px;
  right: 0;
  display: block;
  text-align: right;
  padding-right: 8px;
}
.history .details .sym .percent-tag {
  position: absolute;
  right: -5px;
  display: flex;
  transform: scale(.8);
}
.history .details .sym > span {
  color: hsla(220, 39%, 80%, .8);
}
.history .details .sym .active-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: white;
  display: inline-block;
  margin-left: 10px;
  transform: translateY(-.55em);

  -webkit-animation-name: pulse-dot;

          animation-name: pulse-dot;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: forward;
          animation-direction: forward;
}
@-webkit-keyframes pulse-dot {
  0% { opacity: .5; }
  50% { opacity: 1; }
  100% { opacity: .5; }
}
@keyframes pulse-dot {
  0% { opacity: .5; }
  50% { opacity: 1; }
  100% { opacity: .5; }
}
.history .details .balance {
  position: relative;
  font-size: 35px;
  line-height: 1em;
  display: flex;
  align-items: flex-start;
  font-weight: 200;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.history .details .balance small {
  font-size: .5em;
  line-height: 1em;
  margin-top: 3px;
  align-self: flex-start;
  letter-spacing: 1px;
  margin-left: 2px;
  font-weight: 400;
}
.history .details .percent {
  margin-left: 15px;
  font-size: 15px;
  height: 25px;
  align-self: flex-end;
}
.history .details .metrics {
  margin-bottom: -20px;
}
.history .details .metrics.active {
  margin-bottom: 0;
  margin-top: 20px;
  background: rgba(0,0,30,.1);
  border: 2px solid white;
  border: none;
  border-radius: 10px;
  padding: 20px;
  padding-top: 17px;
  padding-bottom: 0;
}
.history .details .metric {
  display: inline-block;
  padding-right: 10px;
  width: 30%;
  margin-bottom: 20px;
}
.history .details .metrics.active .metric {
  width: 25%;
}
.history .details .metrics.active .metric.double {
  width: 50%;
  padding-right: 0;
}
.history .details .metrics:not(.active) .metric:nth-of-type(3n) {
  padding-right: 0;
  width: 40%;
}
.history .details .metrics.active .mute {
  display: inline-block;
  margin: 0 2px;
  opacity: .5;
}
.history .details .metric .header {
  font-size: 12px;
  color: hsla(220, 39%, 80%, .8);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.history .details .year .toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
  margin: -20px -10px;
  padding: 20px 10px;
  border-radius: 10px;
  min-height: 65px;
  font-size: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.history .details .year h1 {
  font-size: 25px;
  margin: 0;
  font-weight: 400;
}
.history .details .year .toggle > div > :first-child {
  display: inline-block;
  font-weight: 200
}
.history:not(.mobile) .details .year .toggle:not(.disabled):hover {
  cursor: pointer;
  background: rgba(0,0,30,.1);
}
.history .details .year .toggle.disabled {
  color: hsla(220, 39%, 80%, .8);
}
.history .details .year .extended {
  margin-top: 40px;
  margin-bottom: 20px;
}
.history .details .year .extended .trades {
  margin-top: 40px;
}
.history .details .year .extended .trade {
  background: rgba(0,0,30,.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  padding-right: 8px;
  margin: 5px -5px;
  border-radius: 10px;
}
.history .details .year .extended .trade > :first-child {
  font-size: 13px;
  color: hsla(220, 39%, 80%, .8);
}
.history .details .year .extended .trade > :last-child > :first-child {
  display: inline-block;
  transform: translateY(-1px);
}
.history .details .year .extended .trade .percent-tag {
  border-radius: 6px;
  font-size: 12px;
  padding: 0px 5px;
  height: 20px;
}
.history .details .year .extended .trade .percent-tag svg {
  width: 10px;
  margin-right: 5px;
}
.history.mobile .details .year .toggle .percent-tag {
  transform: scale(.8);
  margin-left: 2px;
}



.history .stock-opts li {
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: rgba(0,0,30,.3);
  border-radius: 100px;
  margin: 4px;
  color: white;
  opacity: 1;
  border: 2px solid transparent;
}
.history .symbols-list .stock-opts li,
.history .empty-details .stock-opts li {
  transform: scale(0);
}
.history .stock-opts li .active-dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 2px;
  background: white;
  transform: translateY(-.44em);
}
.history:not(.mobile) .stock-opts li:hover {
  border-color: hsla(220, 39%, 80%, .8);
}
.history .stock-opts li.single.active,
.history .stock-opts li.active {
  border-color: white;
  border-radius: 50px;
  background: rgba(0,0,30,.3);
}
.history .stock-opts li.active-trade {
  color: #4dffff;
  background: rgba(0,0,30,.3);
}
.history .stock-opts li.active.active-trade {
  border-color: #4dffff;
}
.history .stock-opts li.active-trade .active-dot {
  background: #4dffff;
  transform: translateY(-.5em);
}

.history .details > .stock-opts {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px 20px;
  position: relative;
  background: rgba(0,0,30,.2);
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
}
.history .details > .stock-opts li:first-child {
  margin-left: -10px;
}
.history .details > .stock-opts li:last-child {
  margin-right: -10px;
}
.history .empty-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  min-height: 100%;
}
.history .empty-details .stock-opts {
  width: 100%;
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
}
.history.mobile .detail-transactions {
  margin-top: 60px;
}
.history.mobile .details .transactions {
  padding: 0;
  margin-top: 20px;
}
.history.mobile .details .footer {
  margin-bottom: 0;
}

.history .clear {
  display: none;
  height: 60px;
  width: 60px;
  padding: 20px;
  border-radius: 30px;
  position: absolute;
  right: -15px;
  top: -5px;
  cursor: pointer;
}
.history .clear svg {
  height: 20px;
  width: 20px;
}
.history .clear:not(:hover) svg {
  fill: hsla(220, 39%, 80%, .8);
}

@media only screen and (max-width: 767px) {
  .history:not(.mobile) .content {
    flex-direction: column;
  }
  .history:not(.mobile) .transactions {
    height: 100%;
  }
  .history:not(.mobile) .details {
    width: 100%;
    border-left: none;
    height: 30%;
    flex-shrink: 0;
    flex-grow: 0;
    border-top: 3px solid rgba(0,0,30,.2);
    transition: height .15s;
  }
  .history:not(.mobile) .details.with-sym {
    height: 75%;
  }
  .history:not(.mobile) .clear {
    display: flex;
  }
}

.history .symbols-list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  flex-grow: 1;
}
.history.mobile .symbols-list {
  padding: 40px 15px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}
.history .symbols-list .stock-opts {
  display: block;
  text-align: center;
}
.history .symbols-list-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.history .symbols-list .stock-opts li {
  font-size: 20px;
}
.history .symbols-list .stock-opts li.active {
  background: rgba(0,0,30,.3);
  border-color: hsla(220, 39%, 80%, .8);
  border-radius: 100px;
}
.interface {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  max-height: 1200px;
  display: flex;
  flex-direction: column;
}

.interface.blur .interface-content {
  -webkit-filter: blur(5px);
          filter: blur(5px);
}

.interface > .bg {
  display: none;
  pointer-events: none;
  z-index: -10;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.15);
  position: fixed;
  top: 0;
  left: 0;
}

.interface-content {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  transition: blur .35s;
}

.tile-col { width: 50% }

.tile-container {
  border-radius: 10px;
  padding: 8px;
  transform: translate3d(0,0,0);
}
.mobile .tile-container {
  opacity: 0;
}

#portfolio, #balances, #volatility { height: 33.3333333% }
#breakdown { height: 45% }
#charts { height: 55% }

.tile-col.left .tile-container { padding-left: 16px }
.tile-col.right .tile-container { padding-right: 16px }
.tile-col > :last-child { padding-bottom: 16px }

.tile-container .tile {
  height: 100%;
  width: 100%;
  padding: 3vh;
  background: rgba(0,0,30,.4);
  box-shadow: 0 0 16px rgba(0,0,0,0.12), 0 0 16px rgba(0,0,0,0.18);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.mobile .tile-container .tile {
  box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.1);
}
.tile .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: inherit;
  line-height: 1em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tile .header .title {
  color: hsla(220, 39%, 80%, .8);
  font-size: 1.2em;
  font-weight: 100;
}
.tile .body {
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 0;
  position: relative;
}
.tile .data {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  z-index: 20;
  transform: translate3d(0,100%,0);
}
.tile .data > div {
  width: 100%;
}
.tile .data, .tile .main {
  transition: transform .3s, opacity .15s, -webkit-filter .3s;
  transition: transform .3s, opacity .15s, filter .3s;
  transition: transform .3s, opacity .15s, filter .3s, -webkit-filter .3s;
}
.tile.show-data .data {
  pointer-events: all;
  opacity: 1;
  transform: translate3d(0,0,0);
}
.tile.show-data .main {
  pointer-events: none;
  -webkit-filter: blur(30px);
          filter: blur(30px);
  transform: translate3d(0,0,0);
}
.tile:not(:hover):not(.show-data) > .data-toggle {
  display: none;
}
.tile .data-toggle {
  position: absolute;
  top: 3vh;
  right: 3vh;
  padding: .5em;
  height: 2em;
  width: 2em;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: transform .1s, opacity .2s;

  display: flex;
  justify-content: center;
  align-items: center;
}
.tile .data-toggle:hover {
  transform: scale(1.2);
}
.tile .data-toggle:active {
  transform: scale(.8);
}
.tile .data-toggle:hover svg,
.tile .data-toggle.active svg {
  fill: white;
}
.tile .data-toggle svg {
  fill: hsla(220, 39%, 80%, .8);
  height: 100%;
  width: 100%;
}
.tile .data-toggle span {
  font-size: 2em;
  display: block;
  transform: rotate(45deg);
  color: hsla(220, 39%, 80%, .8);
}
.tile .data-toggle:hover span {
  color: white;
}
.tile .data-toggle #threedot {
  transform: rotate(90deg);
}

@media only screen and (max-width: 767px) {
  .app:not(.mobile) .interface-content { 
    flex-direction: column;
    overflow: auto;
  }
  .tile-col { width: 100% }
  .app:not(.mobile) .tile-container { 
    height: 300px!important;
    padding: 8px 16px; 
  }
  .app:not(.mobile) .tile {
    padding: 20px;
  }
  .app:not(.mobile) .tile .data-toggle {
    top: 20px;
    right: 30px;
  }
  .app:not(.mobile) .interface-header .app-opts {
    display: none;
  }
  .app:not(.mobile) .interface-header .dates {
    padding-left: 0;
    text-align: left;
  }
  .app .tile-container .tile {
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.1);
  }
}

.mobile .tile-container { height: 100%!important }
.swipe-shrink, .swipe, .swipe-wrap, .swipe-wrap > div { height: 100% }
.mobile .interface-content { flex-direction: column }
.mobile .interface-content > section { 
  height: 40%; 
  padding: 0 10px 4px 10px; 
}
.mobile .interface-content > section:last-of-type {
  margin-bottom: 4px;
  height: 60%;
}
.mobile .tile-container { transition: transform .4s; padding: 4px 4px; }





/* ==== HEADER ==== */

.interface-header {
  overflow:hidden;
  position: relative;
  height: 75px;
  width: 100%;
  font-size: .8em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 0 36px; /* 16 + 20 */
  margin-bottom: -8px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
}
.interface-header .header-slider {
  height: 100%;
  width: 100%;
  transform: translateY(0);
  transition: transform .2s;
}
.interface-header section {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.interface-header .header-slider[data-slide="1"] { transform: translateY(-100%) }
.interface-header .header-slider[data-slide="2"] { transform: translateY(-200%) }

.menu-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 1em;
  fill: white;
  width: 1rem;
  opacity: .5;
  transform: rotate(90deg);
  transition: opacity .1s;
}
.menu-icon:hover { opacity: 1 }

.interface-header .logo { height: 15px } /* replaced logo with date */
.interface-header .header-right, .interface-header .header-left { display: flex }
.interface-header .header-left li { letter-spacing: 1px }
.interface-header .clickitem { transition: transform .1s }
.interface-header :not(.active) > .clickitem,
.interface-header .clickitem:not(.active) { cursor: pointer; }
.interface-header :not(.active):hover > .clickitem,
.interface-header .single:hover > .clickitem { transform: scale(1.1) }
.interface-header :not(.active):active > .clickitem, 
.interface-header .single:active > .clickitem { transform: scale(.8) }
.interface-header .disabled { pointer-events: none; opacity: .2!important; }
.interface-header .dates {
  position: absolute;
  left: 0;
  width: 100%;
  padding-left: calc(1rem + 1em);
  text-align: center;
  z-index: -10;
  font-size: 1.2em;
}

ul[class*="-opts"] {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  position: relative;
}
ul.date-opts { margin-right: -12px }
ul.app-opts { margin-left: -12px }
ul[class*="-opts"] li {
  margin: 0 2px;
  padding: 6px 12px; /* .5em 1em */
  opacity: .5;
  font-weight: 500;
  cursor: pointer;
}
ul[class*="-opts"] li.icon {
  font-size: 1.2em;
}
ul[class*="-opts"] li.single {
  transition: background .1s;
  border-radius: .5em;
}
ul[class*="-opts"] li:not(.active):hover,
ul[class*="-opts"] li:not(.active):hover span { 
  opacity: 1;
}
ul[class*="-opts"] li.active,
ul[class*="-opts"] li.active span,
.interface-header .active > .clickitem { 
  opacity: 1 
}
ul[class*="-opts"] li.single.active {
  background: rgba(255,255,255,.15);
}
ul[class*="-opts"] .divider {
  pointer-events: none;
  padding-right: 0;
}
ul[class*="-opts"] span {
  opacity: .8;
  margin-left: 2px;
}
.app-opts span {
  margin: 0 2px 0 0;
}
.interface-header ul .bg {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255,.1);
  opacity: 0;
  border-radius: .5em;
  transition: all .1s;
}
.interface-header .app-opts .bg {
  background: rgba(255,255,255,.15)
}
#header-cal {
  padding-left: 2.2em; /* same width as menu icon */
}

.percent-tag {
  display: inline-flex;
  border-radius: 100px;
  align-items: center;
  font-size: 1em;
  line-height: 1em;
  height: 1em;
  padding: 0 10px;
  font-weight: 400;
  
  background: rgba(102, 255, 133, 0.2);
  color: #66ff85;
}
.percent-tag svg {
  width: .8em;
  height: auto;
  transform: translateY(-1px) rotate(-45deg);
  margin-right: 5px;
}
.percent-tag svg {
  width: .8em;
  height: auto;
  transform: translateY(-1px) rotate(-45deg);
  margin-right: 5px;
  
  fill: #66ff85;
}
.percent-tag.neg {
  background: rgba(255, 102, 179, 0.2);
  color: #ff66b3;
}
.percent-tag.neg svg {
  fill: #ff66b3;
  transform: translateY(-1px) rotate(45deg);
}




.mobile .interface { 
  -webkit-user-select: none; 
     -moz-user-select: none; 
      -ms-user-select: none; 
          user-select: none;
  top: 0;
  overflow: hidden;
  transition: all .15s ease;
}
.mobile .interface.shrink {
  transform: scale(.95) translateY(-5px);
  background: rgba(0,0,30,.35);
  border-radius: 15px;
  transition: all .3s ease-out;
}
.mobile .interface-header {
  font-size: 1.1em;
  align-items: center;
  padding: 0 25px;

  margin-bottom: -4px;
  z-index: 10;
}
.mobile .interface-header .dates {
  position: relative;
  padding: 0;
  font-size: 1.1em;
  flex-grow: 1;
  width: 100%;
}
.mobile .interface-header .dates span {
  opacity: .8;
  margin-left: 3px;
}
.mobile .interface-header .quick-action {
  flex-shrink: 0;
  flex-grow: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile .interface-header .quick-action svg {
  height: 100%;
  width: auto;
  fill: white;
}

.mobile-menu {
  position: absolute;
  top: -100vh;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  transform: translate3d(0,0,0);
}
.mobile-menu .hideBanner {
  width: 100vw;
  height: 70px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,.02);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.mobile-menu .hideBanner svg {
  height: 30px;
  width: 50px;
  fill: rgba(255,255,255,0.3);
  transform: scaleY(-1)
}
.mobile-menu .date-pickers {
  width: 100%;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
}
.mobile-menu .date-pickers .from,
.mobile-menu .date-pickers .to {
  position: relative;
  width: 8em;
  height: 10em;
  padding-bottom: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.05);
  border-radius: 2em;
  overflow: hidden;
}
.mobile-menu .text {
  text-align: center;
}
.mobile-menu .text .big {
  font-size: 4em;
  /*font-weight: 100;*/
  line-height: 1em;
}
.mobile-menu .text .small {
  text-transform: uppercase;
  opacity: .7;
  font-weight: 500;
}
.mobile-menu input[type="date"] {
  height: 100%;
  width: 50vw;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.mobile-menu .opts {
  width: 100%;
  padding: 0 10%;
  display: flex;
  justify-content: space-around;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 10vh;
}
.mobile-menu .opts .opt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: 700;
  border-radius: 50%;
  color: white;
  opacity: .2;
  position: relative;
  text-transform: uppercase;
  transition: opacity .15s;
}
.mobile-menu .opts .opt:not(.single):before {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
  background: white;
  opacity: 0;
  transition: opacity .15s;
}
.mobile-menu .opts .opt.active { opacity: 1 }
.mobile-menu .opts .opt.active:before { opacity: .6 }

.mobile-menu { transition: top .3s, -webkit-filter .3s; transition: top .3s, filter .3s; transition: top .3s, filter .3s, -webkit-filter .3s }
.mobile.app { transition: background .3s }
/*
== COLORS ==
#18ecdd hsl(176, 85%, 51%) = green
#4a8efc hsl(217, 97%, 64%) = blue   x
#aec2fa hsl(224, 88%, 83%) = purple
#ff85e4 hsl(313, 100%, 76%) = pink
#445db1 hsl(226, 44%, 48%) = darkblue

hsla(220, 39%, 80%, .8) = practically opacity white but blue
*/

* {
  outline: none;
  box-sizing: border-box;
}
.mobile * {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
::selection {
  background: rgba(255,255,255,.1);
}
.hide-scroll::-webkit-scrollbar,
.mobile *::-webkit-scrollbar {
  display: none;
}

html {
  background: #069cc2;
  /*background: linear-gradient(135deg, hsl(179 100% 45% / 1), hsl(217 100% 45% / 1), hsl(285 100% 43% / 1));*/
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  color: white;
  width: 100%;
  height: 100vh;
  font-size: 15px;
  z-index: 10;
  position: fixed;
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
  background: #069cc2;
  /*background: linear-gradient(135deg, rgba(20,207,204,1), rgba(56,101,173,1), rgba(130,69,150,1));*/
  background: linear-gradient(135deg, hsl(179 100% 45% / 1), hsl(217 100% 45% / 1), hsl(285 100% 43% / 1));
}

.app {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: rgba(0,0,0,.15);*/
}

.app > .rotate {
  display: none;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.app > .rotate svg { height: 20vh; width: 20vh; }
.app > .rotate .primary { fill: rgba(255,255,255,0.5); }
.app > .rotate .secondary { fill: rgba(255,255,255,0.1); }

 @media screen and (orientation:landscape) {
/*  .mobile.app {
    height: 100vw;
    width: 100vh;
    transform: translateX(-100%) rotate(-90deg); 
    transform-origin: right top;
  }*/
  .mobile.app:not(.dev) > :not(.rotate) { display: none; }
  .mobile.app:not(.dev) > .rotate { display: flex; }
}

.unlock {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.6);
  opacity: 0;
  overflow: hidden;
}
.unlock .input {
  text-align: center;
  -webkit-text-security: disc;
  cursor: text;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 1em;
  color: white;
  padding-left: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.unlock .input:empty:before {
  content: 'pass';
  opacity: .2;
  margin-left: 1em;
}
.unlock .input:after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}
.unlock .logo {
  width: 40%;
  margin-bottom: 50px;
}
 .unlock-error {
  position: absolute;
  bottom: 15%;
  cursor: pointer;
  transition: opacity 150ms;
  background: rgba(255,255,255,.1);
  padding: .75em 1.5em;
  font-size: 1em;
  border-radius: .5em;
  z-index: 20;
}
.unlock-error:hover { opacity: .8 }
.unlock-error.hide { opacity: 0 }
@media only screen and (max-width: 767px) {
  .unlock .input {
    font-size: 1.2em;
    letter-spacing: .5em;
    padding-left: .5em;
  }
  .unlock .input:empty:before {
    margin-left: .5em;
  }
  .unlock .logo {
    margin-bottom: 25px;
    width: 60%;
  }
}

/* SWIPE LIBRARY */
.swipe {
  visibility: hidden;
  position: relative;
}
.swipe-wrap {
  position: relative;
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
}
